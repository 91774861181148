/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Adresse {
  /** @maxLength 32 */
  adresse1?: string | null;
  /** @maxLength 32 */
  adresse2?: string | null;
  /** @maxLength 32 */
  adresse3?: string | null;
  /** @maxLength 10 */
  codePostal?: string | null;
  /** @maxLength 26 */
  ville?: string | null;
  /** @maxLength 50 */
  codePays?: string | null;
}

export interface AdresseRequest {
  /**
   * @minLength 1
   * @maxLength 32
   */
  adresse1: string;
  /** @maxLength 32 */
  adresse2?: string | null;
  /** @maxLength 32 */
  adresse3?: string | null;
  /**
   * @minLength 1
   * @maxLength 10
   */
  codePostal: string;
  /**
   * @minLength 1
   * @maxLength 26
   */
  ville: string;
  /** @maxLength 25 */
  codePays?: string | null;
}

export interface AffectationMetier {
  /** @format int32 */
  id: number;
  corpsMetier: IdLabel;
  autoriseMissionClassique: boolean;
  autoriseMissionUrgente: boolean;
}

export interface AffectationMetierRequest {
  /** @format int32 */
  idCorpsMetier: number;
  autoriseMissionClassique: boolean;
  autoriseMissionUrgente: boolean;
}

export interface AnnuaireLoginExtranetAssureur {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  idAnnuaire: string;
  /** @minLength 1 */
  login: string;
  password?: string | null;
}

export interface AnnuaireLoginExtranetAssureurResume {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  idAnnuaire: string;
  /** @minLength 1 */
  nom: string;
  /** @minLength 1 */
  famille: string;
  /** @minLength 1 */
  login: string;
  /** @minLength 1 */
  password: string;
}

export interface AnnuaireResume {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nomFamille: string;
}

export interface Anomalie {
  /** @format int32 */
  id: number;
  anomalieType: AnomalieType;
  statut: boolean;
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin?: string | null;
  etapeSuivi?: WorkflowJalon | null;
  commentaire?: string | null;
  /** @format int32 */
  tauxHumidite?: number | null;
  /** @format date-time */
  dateCreation: string;
  /** @format date-time */
  dateModification: string;
  /** @minLength 1 */
  dossierID: string;
}

export interface AnomalieModificationRequest {
  /** @format int32 */
  anomalieType?: number | null;
  statut?: boolean | null;
  /** @format date-time */
  dateFin?: string | null;
  commentaire?: string | null;
  /** @format int32 */
  tauxHumidite?: number | null;
}

export interface AnomalieRequest {
  /** @format int32 */
  anomalieType: number;
  statut: boolean;
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
  etapeSuivi?: WorkflowJalon | null;
  commentaire?: string | null;
  /** @format int32 */
  tauxHumidite?: number | null;
}

export interface AnomalieType {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  nom: string;
  hasTauxHumidite: boolean;
}

export interface AnomalieTypeModificationRequest {
  nom?: string | null;
}

export interface AnomalieTypeRequest {
  /** @minLength 1 */
  nom: string;
}

export interface AskForPasswordResetEntrepriseRequest {
  /** @minLength 1 */
  login: string;
}

export interface Assure {
  /** @minLength 1 */
  id: string;
  /** @maxLength 32 */
  nom?: string | null;
  /** @maxLength 32 */
  prenom?: string | null;
  adresse?: Adresse | null;
  email?: string | null;
  telephone?: string | null;
}

export interface AuthenticationProperties {
  items?: Record<string, string>;
  parameters?: Record<string, any>;
  isPersistent?: boolean;
  redirectUri?: string | null;
  /** @format date-time */
  issuedUtc?: string | null;
  /** @format date-time */
  expiresUtc?: string | null;
  allowRefresh?: boolean | null;
}

export interface BibliothequeDivers {
  categorie: BibliothequeDiversCategorie;
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
}

export enum BibliothequeDiversCategorie {
  Titre = "Titre",
  SousTitre = "SousTitre",
  Commentaire = "Commentaire",
  Autre = "Autre",
}

export interface Bordereau {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
  description?: string | null;
  /** @format double */
  prixUnitaire?: number | null;
  unite?: string | null;
}

export interface ChangeSet {
  dossier?: string | null;
  /** @format date-time */
  dateDernierChangement?: string;
  typeModif?: TypeModif;
}

export interface CodeLabel {
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface Compagnie {
  nom?: string | null;
  numeroContrat?: string | null;
  reference?: string | null;
}

export interface CompagnieAssurance {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
}

export interface ContactEntreprise {
  /** @format int32 */
  id: number;
  /** @format int32 */
  idEntreprise: number;
  nom?: string | null;
  prenom?: string | null;
  email?: string | null;
  telFixe?: string | null;
  telPortable?: string | null;
  role?: string | null;
}

export interface ContactEntrepriseRequest {
  /** @format int32 */
  idEntreprisePartenaire: number;
  /** @minLength 1 */
  nom: string;
  prenom?: string | null;
  email?: string | null;
  telFixe?: string | null;
  telPortable?: string | null;
  /** @minLength 1 */
  role: string;
}

export interface CoordoneesRequest {
  adresse?: AdresseRequest | null;
  email?: string | null;
  telephonePortable?: string | null;
  telephoneFixe?: string | null;
}

export interface Coordonnees {
  adresse?: Adresse | null;
  email?: string | null;
  telephone?: string | null;
}

export interface CreationDossierDommageRequest {
  /** @format double */
  montant?: number | null;
  commentaire?: string | null;
}

export interface CreationDossierPersonneRequest {
  /** @minLength 1 */
  nom: string;
  prenom?: string | null;
  telephoneMobile?: string | null;
  telephoneFixe?: string | null;
  adresse1?: string | null;
  adresse2?: string | null;
  adresse3?: string | null;
  /** @minLength 1 */
  codePostal: string;
  /** @minLength 1 */
  ville: string;
}

export interface CreationDossierRequest {
  typeDePrestation: TypePrestation;
  urgence: boolean;
  /** @minLength 1 */
  numeroContrat: string;
  /** @format double */
  montantFranchise?: number | null;
  assure: CreationDossierPersonneRequest;
  sinistre: CreationDossierSinistreRequest;
  dommage: CreationDossierDommageRequest;
  messagePrivePrestataire?: string | null;
}

export interface CreationDossierSinistreRequest {
  /** @minLength 1 */
  numero: string;
  /** @minLength 1 */
  nature: string;
  /** @format date-time */
  date?: string | null;
  nom?: string | null;
  adresse1?: string | null;
  adresse2?: string | null;
  /** @minLength 1 */
  codePostal: string;
  /** @minLength 1 */
  ville: string;
}

export enum CurrentCollaborateurType {
  ExtranetAssure = "ExtranetAssure",
  ExtranetEntreprise = "ExtranetEntreprise",
  ExtranetAssureur = "ExtranetAssureur",
  Iren = "Iren",
  Generic = "Generic",
}

export interface DashboardFacture {
  /** @minLength 1 */
  dossier: string;
  /** @minLength 1 */
  assure: string;
  /** @minLength 1 */
  donneurDordre: string;
  /** @minLength 1 */
  refMendant: string;
  /** @minLength 1 */
  ville: string;
  /** @format int64 */
  numeroFacture: number;
  /** @minLength 1 */
  type: string;
  /** @format double */
  montantHT: number;
  statut?: EtatFactureEnumLabel | null;
  /** @format date-time */
  dateStatus?: string | null;
}

export interface Devis {
  deductionFranchise: boolean;
  /** @format int32 */
  sequence?: number;
  ordre?: string | null;
  /** @format double */
  montantFranchise?: number | null;
  rse: boolean;
  /** @format float */
  tauxRSE?: number | null;
  remise: boolean;
  /** @format float */
  tauxRemise?: number | null;
  commentaire?: string | null;
  etatDuDevis?: EtatDevisEnumLabel | null;
  motifRefusDevis?: string | null;
  lignesDevis?: LigneDevis[] | null;
  piedDuDevis?: PiedDevis | null;
}

export interface DevisAvecPiecesJointes {
  pieceJointeSimplifie: PieceJointeSimplifie;
  deductionFranchise: boolean;
  /** @format int32 */
  sequence?: number;
  ordre?: string | null;
  /** @format double */
  montantFranchise?: number | null;
  rse: boolean;
  /** @format float */
  tauxRSE?: number | null;
  remise: boolean;
  /** @format float */
  tauxRemise?: number | null;
  commentaire?: string | null;
  etatDuDevis?: EtatDevisEnumLabel | null;
  motifRefusDevis?: string | null;
  lignesDevis?: LigneDevis[] | null;
  piedDuDevis?: PiedDevis | null;
}

export interface DevisRequest {
  deductionFranchise: boolean;
  /** @format int32 */
  sequence?: number;
  /** @format double */
  montantFranchise?: number | null;
  rse: boolean;
  /** @format float */
  tauxRSE?: number | null;
  remise: boolean;
  /** @format float */
  tauxRemise?: number | null;
  commentaire?: string | null;
  lignesDevis?: LigneDevis[] | null;
}

export interface Document {
  id?: string | null;
  categorie?: CodeLabel | null;
  /** @minLength 1 */
  contentType: string;
  /** @format date-time */
  dateCreation?: string | null;
  /** @format date-time */
  dateEdition?: string | null;
  /** @minLength 1 */
  nomDuFichier: string;
  /** @minLength 1 */
  origine: string;
  /** @minLength 1 */
  emetteur: string;
  url?: string | null;
  urlVignette?: string | null;
  /**
   * @minLength 1
   * @maxLength 30
   */
  libelle: string;
  familleAction?: CodeLabel | null;
  action?: CodeLabel | null;
}

export interface DocumentJuridique {
  /** @format int32 */
  id: number;
  typeDocument?: IdLabel | null;
  nom?: string | null;
  isObligatoire?: boolean;
  /** @format date-time */
  dateDemande?: string | null;
  /** @format date-time */
  dateDepot?: string | null;
  /** @format date-time */
  dateValiditeMax?: string | null;
}

export interface DocumentJuridiqueRequest {
  typeDocument: IdLabel;
  /** @minLength 1 */
  nom: string;
  isObligatoire: boolean;
  /** @format date-time */
  dateDemande?: string;
  /** @format date-time */
  dateDepot: string;
  /** @format date-time */
  dateValiditeMax: string;
}

export interface DocumentJuridiqueType {
  /** @format int32 */
  id: number;
  libelle?: string | null;
  isObligatoire?: boolean;
  isPermanent?: boolean;
  /** @format int32 */
  dureeValiditeJour?: number;
}

export interface DocumentRequest {
  /** @format int32 */
  action?: number | null;
  type?: TypeDocument | null;
  /** @format binary */
  fichier: File;
}

export interface Dossier {
  /** @minLength 1 */
  id: string;
  naturesSinistre?: NatureSinistre | null;
  assure?: Personne | null;
  /** @format date-time */
  dateSinistre?: string | null;
  descriptionDommages?: string | null;
  /** @format double */
  montantEstime?: number | null;
  adresseSinistre: Adresse;
  statutDossier?: StatutDossier | null;
  compagnieAssurance?: CompagnieAssurance | null;
  /** @minLength 1 */
  numeroContrat: string;
  /** @minLength 1 */
  referenceCompagnie: string;
  typePrestation?: TypePrestationEnumLabel | null;
  gestionnaire?: string | null;
  /** @format date-time */
  dateMissionnement?: string | null;
  /** @format date-time */
  dateCreation?: string | null;
  statutComposeDossier: StatutComposeDossier;
  etatMissionnement?: EtatMissionnement | null;
}

export interface DossierRecherche {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  assure: string;
  /** @minLength 1 */
  referenceSinistre: string;
  /** @minLength 1 */
  referenceCompagnie: string;
  /** @minLength 1 */
  numeroContrat: string;
  natureSinistre: NatureSinistre;
  entreprisePartenaire?: EntreprisePartenaire | null;
  /** @minLength 1 */
  adresse: string;
  /** @minLength 1 */
  codePostal: string;
  /** @minLength 1 */
  ville: string;
  missionUrgente: boolean;
  /** @format date-time */
  dateMissionnement?: string | null;
  /** @format date-time */
  dateCreation?: string | null;
  /** @format date-time */
  dateModification?: string | null;
  /** @format date-time */
  dateSinistre?: string | null;
  compagnieDAssurance?: CompagnieAssurance | null;
  /** @format date-time */
  dateCharte: string;
  statut: StatutDossier;
  statutComposeDossier: StatutComposeDossier;
  etatMissionnement: EtatMissionnement;
  derniereAnomalieNonLevee?: Anomalie | null;
}

export interface EchangeInterne {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  idDossier: string;
  /** @minLength 1 */
  objet: string;
  /** @minLength 1 */
  message: string;
  /** @format date-time */
  date: string;
  lu: boolean;
  emetteur: EchangeInterneEmetteur;
  destinataire: EchangeInterneDestinataire;
}

export interface EchangeInterneDestinataire {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
}

export interface EchangeInterneEmetteur {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
}

export interface EchangeInterneRequest {
  /**
   * Le ou les destinataire de l'EI
   * Pourraient être récupérés de la route :  Dossier/{dossier}/EchangeInterne/Destinataires
   * Un destinataire doit avoir le format RegEx : RefAnnuaire
   */
  destinataires?: string[] | null;
  /**
   * L'objet de l'EI
   * @minLength 1
   */
  objet: string;
  /**
   * Message de l'EI
   * @minLength 1
   */
  message: string;
}

export interface ElementMediatheque {
  id?: string | null;
  categorie?: CodeLabel | null;
  sousCategorie?: CodeLabel | null;
  /** @minLength 1 */
  contentType: string;
  /** @format date-time */
  dateCreation?: string | null;
  /** @format date-time */
  dateEdition?: string | null;
  /** @minLength 1 */
  fileName: string;
  /** @minLength 1 */
  origine: string;
  /** @minLength 1 */
  emetteur: string;
  /** @minLength 1 */
  url: string;
  /** @minLength 1 */
  urlVignette: string;
  /**
   * @minLength 1
   * @maxLength 30
   */
  libelle: string;
  type: TypeElementMediatheque;
}

export interface ElementMediathequeRequest {
  /** @format binary */
  fichier: File;
  categorie?: string | null;
  sousCategorie?: string | null;
  type?: MediathequeType | null;
  /** @minLength 1 */
  nom: string;
}

export interface EntreprisePartenaire {
  /** @format int32 */
  id: number;
  raisonSociale?: string | null;
  dirigeantNom?: string | null;
  dirigeantPrenom?: string | null;
  dirigeantTel?: string | null;
  /** @format date-time */
  dateImmatriculation?: string | null;
  siegeSocialCoordonees: Coordonnees;
  siren?: string | null;
  codeApe?: string | null;
  numeroTvaIntracommunication?: string | null;
  codeEntreprise?: string | null;
  codeFormeJuridique?: string | null;
  /** @format double */
  capitalSocial?: number | null;
  cheminLogo?: string | null;
  typeContratPartenaire?: IdLabel | null;
  archive: boolean;
  actif: boolean;
  etablissements: Etablissement[];
  documentJuridiques: DocumentJuridique[];
}

export interface EntreprisePartenaireRequest {
  /** @minLength 1 */
  raisonSociale: string;
  dirigeantNom?: string | null;
  dirigeantPrenom?: string | null;
  dirigeantTel?: string | null;
  /** @format date-time */
  dateImmatriculation?: string;
  siegeSocialCoordonees?: CoordoneesRequest | null;
  siren?: string | null;
  codeApe?: string | null;
  numeroTvaIntracommunication?: string | null;
  codeEntreprise?: string | null;
  codeFormeJuridique?: string | null;
  /** @format double */
  capitalSocial?: number | null;
  cheminLogo?: string | null;
  /** @format int32 */
  idTypeContratPartenaire: number;
  archive: boolean;
  actif: boolean;
}

export interface Etablissement {
  /** @format int32 */
  id: number;
  refAnnuaire: RefAnnuaire;
  raisonSociale?: string | null;
  entreprise?: IdLabel | null;
  coordonnees: Coordonnees;
  siret?: string | null;
  archive: boolean;
  actif: boolean;
  affectationCorpsMetiers: AffectationMetier[];
  contratEntreprise?: IdLabel | null;
  /** @format double */
  score?: number | null;
  /** @format int32 */
  volumeRestant?: number;
  contacts: ContactEntreprise[];
  conges: EtablissementConge[];
  horaires: EtablissementHoraire[];
  zones: ZoneDeCouverture[];
}

export interface EtablissementConge {
  /** @format int32 */
  id: number;
  /** @format date-time */
  dateDebut?: string | null;
  /** @format date-time */
  dateFin?: string | null;
}

export interface EtablissementCongeRequest {
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
}

export interface EtablissementHoraire {
  /** @format int32 */
  id: number;
  /** @format int32 */
  dayOfWeek: number;
  /** @format int64 */
  heureDebut: number;
  /** @format int64 */
  heureFin: number;
}

export interface EtablissementHoraireRequest {
  /** @format int32 */
  dayOfWeek: number;
  /** @format int64 */
  heureDebut: number;
  /** @format int64 */
  heureFin: number;
}

export interface EtablissementRequest {
  refAnnuaire: RefAnnuaire;
  /** @minLength 1 */
  raisonSociale: string;
  coordonees?: Coordonnees | null;
  siret?: string | null;
  archive: boolean;
  actif: boolean;
}

export enum EtatDevis {
  Valide = "Valide",
  Refuse = "Refuse",
  Brouillon = "Brouillon",
  Transmis = "Transmis",
  Retransmis = "Retransmis",
}

export interface EtatDevisEnumLabel {
  code: EtatDevis;
  libelle?: string | null;
}

export enum EtatFacture {
  Brouillon = "Brouillon",
  Creation = "Creation",
  Valide = "Valide",
  Refuse = "Refuse",
  Envoye = "Envoye",
  Regle = "Regle",
  Partiel = "Partiel",
}

export interface EtatFactureEnumLabel {
  code: EtatFacture;
  libelle?: string | null;
}

export enum EtatMissionnement {
  EnAttente = "EnAttente",
  Accepte = "Accepte",
  Refuse = "Refuse",
  Annule = "Annule",
}

export interface Eurogex {
  /** @minLength 1 */
  section: string;
  /** @minLength 1 */
  rubrique: string;
  valeur?: string | null;
  /** @minLength 1 */
  description: string;
}

export interface ExpAssureReponsesRequest {
  /**
   * Code de la question
   * @minLength 1
   */
  codeQuestion: string;
  /**
   * Note
   * @format int32
   */
  note: number;
}

export interface ExtranetEntrepriseUser {
  /** @minLength 1 */
  login: string;
  active: boolean;
  invitationUrl?: string | null;
  /** @format int32 */
  idEntreprise?: number | null;
  /** @format int32 */
  idEtablissement?: number | null;
}

export interface FactureAPI {
  deductionFranchise: boolean;
  /** @format int32 */
  sequence?: number;
  /** @format double */
  montantFranchise?: number | null;
  rse: boolean;
  /** @format float */
  tauxRSE?: number | null;
  remise: boolean;
  /** @format float */
  tauxRemise?: number | null;
  commentaire?: string | null;
  etatDeLaFacture?: EtatFactureEnumLabel | null;
  lignesFacture?: LigneFacture[] | null;
  piedDeLaFacture?: PiedFacture | null;
}

export interface FactureRequest {
  deductionFranchise: boolean;
  /** @format int32 */
  sequence?: number;
  /** @format double */
  montantFranchise?: number | null;
  rse: boolean;
  /** @format float */
  tauxRSE?: number | null;
  remise: boolean;
  /** @format float */
  tauxRemise?: number | null;
  commentaire?: string | null;
  lignesFacture?: LigneFacture[] | null;
}

export interface ForbidResult {
  authenticationSchemes?: string[] | null;
  properties?: AuthenticationProperties | null;
}

export interface IdLabel {
  /** @format int32 */
  id: number;
  libelle?: string | null;
}

export interface Intervention {
  /** @format int32 */
  id: number;
  /** @format date-time */
  dateDebut?: string | null;
  /** @format date-time */
  dateFin?: string | null;
  resultat?: ResultatInterventionEnumLabel | null;
  motifNonReparation?: InterventionMotifNonReparation | null;
  commentaire?: string | null;
  differee: boolean;
  origineDemandeInterventionDiffere?: OrigineDemandeInterventionDiffereeEnumLabel | null;
  /** @format date-time */
  dateCreation: string;
  /** @format date-time */
  dateModification?: string | null;
}

export interface InterventionMotifNonReparation {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  libelle: string;
}

export interface InterventionRequest {
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
  resultat: ResultatIntervention;
  /** @format int32 */
  motifNonReparation?: number | null;
  differee?: boolean;
  origineDemandeInterventionDifferee?: OrigineDemandeInterventionDifferee | null;
  commentaire?: string | null;
}

export interface JalonCreation {
  type: WorkflowJalonEnumLabel;
  /** @format date-time */
  dateCreation: string;
  /** @format date-time */
  dateAnnulation?: string | null;
  etat: WorkflowEtat;
}

export interface JalonDevis {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  dateAffichee?: string | null;
  /** @format date-time */
  dateCreation?: string | null;
  /** @format date-time */
  dateModification?: string | null;
  /** @format date-time */
  dateBrouillon?: string | null;
  devis?: Devis | null;
  /** @format int32 */
  nombreDevisRefuses?: number;
  pieceJointe?: PieceJointe | null;
}

export interface JalonFacture {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  dateAffichee?: string | null;
  /** @format date-time */
  dateCreation?: string | null;
  /** @format date-time */
  dateModification?: string | null;
  facture?: FactureAPI | null;
  piecejointe?: PieceJointe | null;
}

export interface JalonFinDeTravaux {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  dateValidation?: string | null;
  sousJalonAttestationTva: SousJalonAttestationTva;
  sousJalonPhotoApresTravaux: SousJalonPhotoApresTravaux;
  sousJalonAttestationFinDeTravaux: SousJalonAttestationFinDeTravaux;
  sousJalonRapportRechercheDeFuite?: SousJalonRapportRechercheDeFuite | null;
}

export interface JalonIntervention {
  type: WorkflowJalonEnumLabel;
  intervention?: Intervention | null;
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface JalonMissionnement {
  type: WorkflowJalonEnumLabel;
  missionnement?: Missionnement | null;
  etat: WorkflowEtat;
}

export interface JalonPostIntervention {
  type: WorkflowJalonEnumLabel;
  sousJalonPhotoAvantTravaux: SousJalonPhotoAvantTravaux;
  sousJalonPhotoApresTravaux: SousJalonPhotoApresTravaux;
  sousJalonAttestationTva: SousJalonAttestationTva;
  sousJalonBonDeCommande: SousJalonBonDeCommande;
  sousJalonAttestationFinDeTravaux: SousJalonAttestationFinDeTravaux;
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface JalonPostRdvMetre {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  dateValidation?: string | null;
  sousJalonPhotoAvantTravaux: SousJalonPhotoAvantTravaux;
  sousJalonConfirmationDeVisite: SousJalonConfirmationDeVisite;
  sousJalonBonDeCommande: SousJalonBonDeCommande;
}

export interface JalonPriseDeContact {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  dateValidation?: string | null;
  priseDeContact?: PriseDeContact | null;
}

export interface JalonRendezVousMetre {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  dateRendezVous?: string | null;
  rendezVousMetre?: RendezVousMetreur | null;
}

export interface JalonRendezVousTravaux {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  rendezVousTravaux: RendezVousTravaux[];
}

export interface JalonSimplifie {
  /** @minLength 1 */
  libelle: string;
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  date: string;
  actionDate?: string | null;
  pieceJointe?: PieceJointe | null;
}

export interface LigneDevis {
  /**
   * @minLength 1
   * @maxLength 3
   */
  type: string;
  /** @maxLength 80 */
  codeArticle?: string | null;
  libelle?: string | null;
  /** @maxLength 1 */
  unite?: string | null;
  /** @format double */
  quantite?: number | null;
  /** @format double */
  prixUnitaire?: number | null;
  /** @format double */
  prixHT?: number | null;
  /** @maxLength 5 */
  tva?: string | null;
  /** @format double */
  prixTTC?: number | null;
}

export interface LigneFacture {
  /** @maxLength 80 */
  codeArticle?: string | null;
  libelle?: string | null;
  /** @format double */
  quantite?: number | null;
  /** @format double */
  prixUnitaire?: number | null;
  /** @format double */
  prixHT?: number | null;
  /** @maxLength 5 */
  tva?: string | null;
  /** @format double */
  prixTTC?: number | null;
  /**
   * @minLength 1
   * @maxLength 3
   */
  type: string;
  /** @maxLength 1 */
  unite?: string | null;
}

export interface LigneTVAPiedDevis {
  codeTVA?: string | null;
  /** @format double */
  montantHT?: number | null;
  /** @format double */
  montantTVA?: number | null;
  /** @format double */
  montantTTC?: number | null;
}

export interface LigneTVAPiedFacture {
  codeTVA?: string | null;
  /** @format double */
  montantHT?: number | null;
  /** @format double */
  montantTVA?: number | null;
  /** @format double */
  montantTTC?: number | null;
}

export interface LoginRequest {
  /** @minLength 1 */
  username: string;
  /** @minLength 1 */
  password: string;
}

export interface LoginResponse {
  /** @minLength 1 */
  token: string;
  /** @format date-time */
  dateValidite: string;
  /** @minLength 1 */
  nom: string;
  /** @minLength 1 */
  idAnnuaire: string;
}

export interface LoginResponseDossier {
  referenceDossier: RefDossier;
  lectureSeule: boolean;
  /** @minLength 1 */
  token: string;
  /** @format date-time */
  dateValidite: string;
  /** @minLength 1 */
  nom: string;
  /** @minLength 1 */
  idAnnuaire: string;
}

export enum MediathequeType {
  PhotoAvantTravaux = "PhotoAvantTravaux",
  PhotoApresTravaux = "PhotoApresTravaux",
}

export interface Message {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  objet: string;
  /** @minLength 1 */
  commentaire: string;
  /** @format date-time */
  date: string;
  lu: boolean;
  emetteur: MessageEmetteur;
  destinataire: MessageDestinataire;
}

export interface MessageDestinataire {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
}

export interface MessageEmetteur {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
}

export interface MessageRequest {
  /** Le ou les destinataire de l'EI */
  destinataires: string[];
  /**
   * L'objet de l'EI
   * @minLength 1
   */
  objet: string;
  /**
   * Message de l'EI
   * @minLength 1
   */
  message: string;
}

export interface Missionnement {
  /** @minLength 1 */
  dossier: string;
  /** @format date-time */
  dateMissionnement: string;
  etatMissionnement: EtatMissionnement;
  motifRefus?: MissionnementMotifRefus | null;
  commentaire?: string | null;
  lectureSeule?: boolean | null;
  /** @format date-time */
  dateModificationEtat: string;
}

export interface MissionnementEtatRequest {
  etat: EtatMissionnement;
  /** @format int32 */
  motifRefus?: number | null;
  commentaire?: string | null;
}

export interface MissionnementMotifRefus {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  libelle: string;
  urgentUniqement: boolean;
  typeRefus: boolean;
}

export interface MissionnementRequest {
  /** @format int32 */
  idEtablissement: number;
}

export interface NatureSinistre {
  code?: string | null;
  libelle?: string | null;
}

export interface NombreResultatParStatut {
  statutComposeDossier?: StatutComposeDossier | null;
  /** @format int32 */
  nombreResultat?: number;
}

export interface NombreResultatParStatutFacture {
  etatFacture?: EtatFactureEnumLabel | null;
  /** @format int32 */
  nombreResultat?: number;
}

export enum OrigineDemandeInterventionDifferee {
  NonPrecise = "NonPrecise",
  Beneficiaire = "Beneficiaire",
  Prestataire = "Prestataire",
}

export interface OrigineDemandeInterventionDiffereeEnumLabel {
  code: OrigineDemandeInterventionDifferee;
  libelle?: string | null;
}

export interface Personne {
  /** @minLength 1 */
  id: string;
  /** @maxLength 32 */
  nom?: string | null;
  /** @maxLength 32 */
  prenom?: string | null;
  adresse?: Adresse | null;
  email?: string | null;
  /** @minLength 1 */
  telephonePortable: string;
  /** @minLength 1 */
  telephoneFixe: string;
}

export interface PersonneRequest {
  /**
   * @minLength 1
   * @maxLength 32
   */
  nom: string;
  /**
   * @minLength 1
   * @maxLength 32
   */
  prenom: string;
  adresse: AdresseRequest;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  telephone: string;
}

export interface PieceJointe {
  id?: string | null;
  /** @minLength 1 */
  fileName: string;
  url?: string | null;
  urlVignette?: string | null;
  /**
   * @minLength 1
   * @maxLength 30
   */
  libelle: string;
}

export interface PieceJointeSimplifie {
  libelle?: string | null;
  fileName?: string | null;
  url?: string | null;
}

export interface PiedDevis {
  /** @format double */
  montantBrutHT?: number | null;
  /** @format double */
  montantRemise?: number | null;
  /** @format double */
  montantHT?: number | null;
  /** @format double */
  montantTVA?: number | null;
  /** @format double */
  montantTTC?: number | null;
  /** @format double */
  montantFranchise?: number | null;
  /** @format double */
  montantNetAPayer?: number | null;
  lignesTVAPiedDevis?: LigneTVAPiedDevis[] | null;
}

export interface PiedFacture {
  /** @format double */
  montantBrutHT?: number | null;
  /** @format double */
  montantRemise?: number | null;
  /** @format double */
  montantHT?: number | null;
  /** @format double */
  montantTVA?: number | null;
  /** @format double */
  montantTTC?: number | null;
  /** @format double */
  montantFranchise?: number | null;
  /** @format double */
  montantNetAPayer?: number | null;
  lignesTVAPiedFacture?: LigneTVAPiedFacture[] | null;
}

export interface PriseDeContact {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  date: string;
  /** @format date-time */
  dateCreation: string;
  libelle?: string | null;
  commentaire?: string | null;
  aboutie: boolean;
}

export interface PriseDeContactRequest {
  /** @format date-time */
  date: string;
  libelle?: string | null;
  commentaire?: string | null;
  aboutie: boolean;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface RefAnnuaire {
  /** @minLength 1 */
  refComplete: string;
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  famille: string;
  professionnel: boolean;
}

export interface RefDossier {
  /** @minLength 1 */
  annee: string;
  /** @minLength 1 */
  source: string;
  /** @minLength 1 */
  numero: string;
  /** @minLength 1 */
  refComplete: string;
}

export interface RefusFactureRequest {
  /** @format int32 */
  motifRefus?: number | null;
  commentaire?: string | null;
}

export interface RegisterEntrepriseRequest {
  /** @minLength 1 */
  username: string;
  /** @format int32 */
  etablissement: number;
  /** @format int32 */
  entreprise: number;
}

export interface RegisterRequest {
  /** @minLength 1 */
  username: string;
  /** @minLength 1 */
  password: string;
  /** @minLength 1 */
  codeAnnuaire: string;
  /** @minLength 1 */
  codeFamille: string;
}

export interface RendezVousMetreur {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
  journeeComplete: boolean;
}

export interface RendezVousMetreurRequest {
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
}

export interface RendezVousTravaux {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
  journeeComplete: boolean;
}

export interface RendezVousTravauxRequest {
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
}

export interface RepondreEchangeInterneRequest {
  /**
   * L'objet de l'EI
   * @minLength 1
   */
  objet: string;
  /**
   * Message de l'EI
   * @minLength 1
   */
  message: string;
}

export interface RepondreMessageRequest {
  /**
   * L'objet de l'EI
   * @minLength 1
   */
  objet: string;
  /**
   * Message de l'EI
   * @minLength 1
   */
  message: string;
}

export interface ResetPasswordEntrepriseRequest {
  /** @minLength 1 */
  token: string;
  /** @minLength 1 */
  newPassword: string;
}

export interface ResetPasswordEntrepriseResponse {
  /** @minLength 1 */
  url: string;
  /** @minLength 1 */
  email: string;
}

export interface ResultatDashboardFacture {
  nombreResultatParStatut: NombreResultatParStatutFacture[];
  resultats: DashboardFacture[];
  /** @format int32 */
  totalResultats: number;
}

export enum ResultatIntervention {
  Repare = "Repare",
  ReparePartiellement = "ReparePartiellement",
  NonRepare = "NonRepare",
}

export interface ResultatInterventionEnumLabel {
  code: ResultatIntervention;
  libelle?: string | null;
}

export interface ResultatRechercheDossier {
  nombreResultatParStatut?: NombreResultatParStatut[] | null;
  /** @format int32 */
  totalResultats?: number;
  resultats?: DossierRecherche[] | null;
}

export interface Sinistre {
  nature?: string | null;
  /** @format date-time */
  date?: string;
  adresse?: Adresse | null;
}

export interface SousJalonAttestationFinDeTravaux {
  attestationFinDeTravaux?: Document | null;
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface SousJalonAttestationTva {
  etat: WorkflowEtat;
  /** @format date-time */
  dateValidation?: string | null;
  attestationTva?: Document[] | null;
}

export interface SousJalonBonDeCommande {
  bonDeCommande?: Document | null;
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface SousJalonConfirmationDeVisite {
  confirmationDeVisite?: Document | null;
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface SousJalonPhotoApresTravaux {
  photoApresTravaux: ElementMediatheque[];
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface SousJalonPhotoAvantTravaux {
  photoAvantTravaux: ElementMediatheque[];
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface SousJalonRapportRechercheDeFuite {
  rapportRechercheDeFuite?: Document | null;
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface StatutComposeDossier {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
}

export enum StatutDevis {
  Annule = "Annule",
  Valide = "Valide",
}

export interface StatutDevisEnumLabel {
  code: StatutDevis;
  libelle?: string | null;
}

export interface StatutDossier {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
}

export interface SuiviSimplifie {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  libelle: string;
  type: TypeSuiviEnumLabel;
  /** @format date-time */
  date?: string | null;
  /** @minLength 1 */
  commentaire: string;
  /** @minLength 1 */
  emetteur: string;
  destinataires: string[];
  pieceJointes: PieceJointeSimplifie[];
  /** @minLength 1 */
  statut: string;
}

export interface SyntheseDossier {
  assure?: Assure | null;
  compagnie?: Compagnie | null;
  sinistre?: Sinistre | null;
  documents?: PieceJointe[] | null;
}

export interface TauxTVA {
  /** TVA */
  tva: CodeLabel;
  /**
   * Taux de TVA
   * @format float
   */
  taux: number;
}

export interface TransfertEchangeInterneRequest {
  destinataires: string[];
}

export enum TypeDocument {
  AttestationTva = "AttestationTva",
  AttestationFinDeTravaux = "AttestationFinDeTravaux",
  BonDeCommande = "BonDeCommande",
  ConfirmationDeVisite = "ConfirmationDeVisite",
  RapportRechercheDeFuite = "RapportRechercheDeFuite",
  Devis = "Devis",
  Facture = "Facture",
  AvisAssurance = "AvisAssurance",
}

export enum TypeElementMediatheque {
  Image = "Image",
  Document = "Document",
}

export enum TypeModif {
  Creation = "Creation",
  Update = "Update",
}

export enum TypePrestation {
  ReparationEnNature = "ReparationEnNature",
  RechercheDeFuite = "RechercheDeFuite",
}

export interface TypePrestationEnumLabel {
  code: TypePrestation;
  libelle?: string | null;
}

export enum TypeRefus {
  ANNULE = "ANNULE",
  REFUSE = "REFUSE",
}

export enum TypeSuivi {
  DocumentAlaFrappe = "DocumentAlaFrappe",
  Rapport = "Rapport",
  TravailAdministratif = "TravailAdministratif",
  EchangeInterne = "EchangeInterne",
  DelaisCharte = "DelaisCharte",
  RendezVous = "RendezVous",
  RendezVousAnnule = "RendezVousAnnule",
  Email = "Email",
  Relance = "Relance",
  Differe = "Differe",
  Datable = "Datable",
  Info = "Info",
  Systeme = "Système",
  Divers = "Divers",
}

export interface TypeSuiviEnumLabel {
  code: TypeSuivi;
  libelle?: string | null;
}

export interface ValidationProblemDetails {
  errors?: Record<string, string[]>;
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface WorkflowEntreprise {
  jalonCreation: JalonCreation;
  jalonMissionnement: JalonMissionnement;
  jalonIntervention?: JalonIntervention | null;
  jalonPostIntervention?: JalonPostIntervention | null;
  jalonPriseDeContact: JalonPriseDeContact;
  jalonRDVMetre: JalonRendezVousMetre;
  jalonPostRdvMetre: JalonPostRdvMetre;
  jalonDevis: JalonDevis;
  jalonRDVTravaux: JalonRendezVousTravaux;
  jalonFinDeTravaux: JalonFinDeTravaux;
  jalonFacture: JalonFacture;
  jalonDossierTermine: JalonSimplifie;
  dossierUrgent: boolean;
}

export enum WorkflowEtat {
  Fait = "Fait",
  NonFait = "NonFait",
  Annule = "Annule",
  EnAttente = "EnAttente",
  NonAboutie = "NonAboutie",
  Brouillon = "Brouillon",
  Anomalie = "Anomalie",
}

export enum WorkflowJalon {
  Reception = "Reception",
  Contact = "Contact",
  RDVMetre = "RDVMetre",
  Devis = "Devis",
  RDVTravaux = "RDVTravaux",
  FinDeTravaux = "FinDeTravaux",
  DossierTermine = "DossierTermine",
  Facture = "Facture",
  Missionnement = "Missionnement",
  AttestationTva = "AttestationTva",
  PostRdvMetre = "PostRdvMetre",
  Creation = "Creation",
  Intervention = "Intervention",
  PostIntervention = "PostIntervention",
}

export interface WorkflowJalonEnumLabel {
  code: WorkflowJalon;
  libelle?: string | null;
}

export interface ZoneDeCouverture {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  codePostal: string;
  autoriseMissionClassique: boolean;
  autoriseMissionUrgente: boolean;
}

export interface ZoneDeCouvertureRequest {
  /** @minLength 1 */
  codePostal: string;
  autoriseMissionClassique: boolean;
  autoriseMissionUrgente: boolean;
}

export type AnomalieDossierUpdatePayload = AnomalieModificationRequest;

export type AnomalieDossierCreatePayload = AnomalieRequest;

export type AnomalieTypeUpdatePayload = AnomalieTypeModificationRequest;

export type AnomalieTypeCreatePayload = AnomalieTypeRequest;

export type LoginAssureurCreatePayload = AnnuaireLoginExtranetAssureur;

export type LoginAuthentificationCreatePayload = LoginRequest;

export type RegisterAuthentificationCreatePayload = RegisterRequest;

export interface LoginExtranetAssureAuthentificationCreateParams {
  refExtranet?: string;
}

export type LoginExtranetAssureurAuthentificationCreatePayload = LoginRequest;

export type LoginExtranetEntrepriseAuthentificationCreatePayload = LoginRequest;

export type RegisterExtranetEntrepriseAuthentificationCreatePayload = RegisterEntrepriseRequest;

export type ResetPasswordExtranetEntrepriseAuthentificationCreatePayload = ResetPasswordEntrepriseRequest;

export type AskForPasswordResetExtranetEntrepriseAuthentificationCreatePayload = AskForPasswordResetEntrepriseRequest;

export type LoginIrenAuthentificationCreatePayload = LoginRequest;

export interface LoginIrenExtranetAuthentificationCreateParams {
  encryptedString?: string;
  typeCollaborateur?: CurrentCollaborateurType;
}

export interface FacturesDashboardListParams {
  Statuts?: EtatFacture[];
  CompagnieAssurance?: string[];
  NatureSinistre?: string[];
  /** @format date-time */
  DateDebut?: string;
  /** @format date-time */
  DateFin?: string;
  /** @format int32 */
  IndexDebut?: number;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  Limite?: number;
  RechercheLibre?: string;
  Departement?: string;
}

export type PiedDevisCreatePayload = DevisRequest;

export interface BrouillonDevisDetailParams {
  refuse?: boolean;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier: string;
}

export type BrouillonDevisCreatePayload = DevisRequest;

export type EditerDevisCreatePayload = DevisRequest;

export interface EditerDevisCreateParams {
  numeroDevis?: string;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier: string;
}

export interface ImportDevisCreatePayload {
  /** @format binary */
  Fichier: File;
  Nom: string;
}

export interface ChangerStatutDevisPartialUpdatePayload {
  /** @format int32 */
  numeroDevis: number;
  statut: StatutDevis;
  motifRefus?: string;
  /** @format binary */
  pieceJointe?: File;
}

export interface MediathequeDossierDetailParams {
  Categorie?: string;
  SousCategorie?: string;
  Type?: MediathequeType;
  dossier: string;
}

export interface MediathequeDossierCreatePayload {
  elementMediathequeRequest?: ElementMediathequeRequest[];
}

export interface DocumentDossierDetailParams {
  AvecBrouillon?: boolean;
  /** @format int32 */
  CodeAction?: number;
  Type?: TypeDocument;
  dossier: string;
}

export interface DocumentDossierCreatePayload {
  documentRequests?: DocumentRequest[];
}

export type NotationAssureDossierCreatePayload = ExpAssureReponsesRequest[];

export interface NotationAssureDossierCreateParams {
  commentaire?: string;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier: string;
}

export type DossierCreatePayload = CreationDossierRequest;

export interface ChangesetDossierListParams {
  /** @format date-time */
  from: string;
}

export interface EchangeInterneListParams {
  /** @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$ */
  destinataire?: string;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier?: string;
  emetteur?: string;
  /** @format int32 */
  startIndex?: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   * @default 100
   */
  limit?: number;
}

export type NouveauEchangeInterneCreatePayload = EchangeInterneRequest;

export type RepondreEchangeInterneCreatePayload = RepondreEchangeInterneRequest;

export type TransfertEchangeInterneCreatePayload = TransfertEchangeInterneRequest;

export interface DestinatairesEchangeInterneDetailParams {
  /**
   * retourner les assistantes
   * @default true
   */
  assistante?: boolean;
  /**
   * retourner les experts
   * @default true
   */
  expert?: boolean;
  /** @default false */
  responsables?: boolean;
  /**
   * la référence du dossier
   * @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$
   */
  dossier: string;
}

export type EntreprisePartenaireUpdatePayload = EntreprisePartenaireRequest;

export type EntreprisePartenaireCreatePayload = EntreprisePartenaireRequest;

export type DocumentEntreprisePartenaireCreatePayload = DocumentJuridiqueRequest;

export type DocumentEntreprisePartenaireUpdatePayload = DocumentJuridiqueRequest;

export type EtablissementEntreprisePartenaireCreatePayload = EtablissementRequest;

export type EtablissementEntreprisePartenaireUpdatePayload = EtablissementRequest;

export type CongeEtablissementCreatePayload = EtablissementCongeRequest;

export type CongeEtablissementUpdatePayload = EtablissementCongeRequest;

export type HoraireEtablissementCreatePayload = EtablissementHoraireRequest;

export type HoraireEtablissementUpdatePayload = EtablissementHoraireRequest;

export type ContactEtablissementCreatePayload = ContactEntrepriseRequest;

export type ContactEtablissementUpdatePayload = ContactEntrepriseRequest;

export type MetiersEtablissementUpdatePayload = AffectationMetierRequest[];

export type ZoneDeCouvertureEtablissementUpdatePayload = ZoneDeCouvertureRequest[];

export type PiedFactureCreatePayload = FactureRequest;

export type BrouillonFactureCreatePayload = FactureRequest;

export type EditerFactureCreatePayload = FactureRequest;

export interface EditerFactureCreateParams {
  numeroFacture?: string;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier: string;
}

export interface ImportFactureCreatePayload {
  /** @format binary */
  Fichier: File;
  Nom: string;
}

export type RefusFactureFacturePartialUpdatePayload = RefusFactureRequest;

export type InterventionDossierCreatePayload = InterventionRequest;

export type InterventionUpdatePayload = InterventionRequest;

export interface LogsListParams {
  /** @format date-time */
  date?: string;
  /**
   * @format int32
   * @min 0
   * @max 10
   * @default 10
   */
  nbJours?: number;
  /** @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$ */
  refCollaborateur?: string;
}

export type MessagesDossierCreatePayload = MessageRequest;

export type RepondreMessagesCreatePayload = RepondreMessageRequest;

export type MissionnementDossierCreatePayload = MissionnementRequest;

export interface MissionnementMotifsRefusListParams {
  TypeRefus: TypeRefus;
  IsUrgent?: boolean;
  ForceUserDonneurDordre?: boolean;
}

export type EtatMissionnementPartialUpdatePayload = MissionnementEtatRequest;

export type AssureDossierCreatePayload = PersonneRequest;

export type CoordonneesAssurePartialUpdatePayload = CoordoneesRequest;

export interface RechercheDossierListParams {
  Statut?: string[];
  CompagnieAssurance?: string[];
  NatureSinistre?: string[];
  /** @format date-time */
  DateDebut?: string;
  /** @format date-time */
  DateFin?: string;
  /** @format int32 */
  IndexDebut?: number;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  Limite?: number;
  RechercheLibre?: string;
  Departement?: string;
}

export interface RechercheBordereauListParams {
  rechercheLibre?: string;
}

export interface RechercheEntrepriseListParams {
  IdsCorpsMetier?: number[];
  CodePostal?: string;
  MissionUrgente?: boolean;
}

export type PriseDeContactDossierCreatePayload = PriseDeContactRequest;

export type MetreurRendezVousCreatePayload = RendezVousMetreurRequest;

export type MetreurRendezVousPartialUpdatePayload = RendezVousMetreurRequest;

export type TravauxRendezVousCreatePayload = RendezVousTravauxRequest;

export type TravauxRendezVousPartialUpdatePayload = RendezVousTravauxRequest;

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) => {
      const formData = new FormData();

      const typeIsArray = (array: any[], key: string) => {
        array.forEach((value: any, index: number) => {
          if (typeof value === "object") {
            Object.keys(value).forEach((k) => formData.append(`${key}[${index}].${k}`, value[k]));
          } else {
            formData.append(`${key}[${index}]`, value);
          }
        });
      };

      // Only if array was tested. Maybe the other paths need some fixes.
      Object.keys(input || {}).forEach((data) => {
        if (Array.isArray(input[data])) {
          typeIsArray(input[data], data);
        } else {
          if (typeof data === "object") {
            Object.keys(data).forEach((key, index) => formData.append(`${data}[${index}].${key}`, data[key]));
          } else {
            formData.append(`${data}`, input[data]);
          }
        }
      });
      return formData;
    },
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title IrenApi
 * @version 1.0.0
 * @contact <euroinfo@euroinfo.fr> (http://www.euroinfo.fr )
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  dossier = {
    /**
     * No description
     *
     * @tags Anomalie
     * @name AnomaliesDossierDetail
     * @request GET:/Dossier/{dossier}/Anomalies
     * @secure
     */
    anomaliesDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Anomalie[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Anomalies`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Anomalie
     * @name AnomalieDossierDetail
     * @request GET:/Dossier/Anomalie/{id}
     * @secure
     */
    anomalieDossierDetail: (id: number, params: RequestParams = {}) =>
      this.request<Anomalie, ValidationProblemDetails>({
        path: `/Dossier/Anomalie/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Anomalie
     * @name AnomalieDossierUpdate
     * @request PUT:/Dossier/Anomalie/{id}
     * @secure
     */
    anomalieDossierUpdate: (id: number, data: AnomalieDossierUpdatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/Anomalie/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Anomalie
     * @name AnomalieDossierCreate
     * @request POST:/Dossier/{dossier}/Anomalie
     * @secure
     */
    anomalieDossierCreate: (dossier: string, data: AnomalieDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Anomalie`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name PiedDevisCreate
     * @summary Calcule le pied d'un devis par rapport aux lignes saisies
     * @request POST:/Dossier/{dossier}/Devis/Pied
     * @secure
     */
    piedDevisCreate: (dossier: string, data: PiedDevisCreatePayload, params: RequestParams = {}) =>
      this.request<PiedDevis, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Devis/Pied`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name BrouillonDevisDetail
     * @summary Charge le devis brouillon de l'entreprise
     * @request GET:/Dossier/{dossier}/Devis/Brouillon
     * @secure
     */
    brouillonDevisDetail: ({ dossier, ...query }: BrouillonDevisDetailParams, params: RequestParams = {}) =>
      this.request<Devis, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Devis/Brouillon`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name BrouillonDevisCreate
     * @summary Création/Modification d'un nouveau devis en brouillon
     * @request POST:/Dossier/{dossier}/Devis/Brouillon
     * @secure
     */
    brouillonDevisCreate: (dossier: string, data: BrouillonDevisCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Devis/Brouillon`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name ReprendreRefuseDevisDetail
     * @request GET:/Dossier/{dossier}/Devis/ReprendreRefuse
     * @deprecated
     * @secure
     */
    reprendreRefuseDevisDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Devis, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Devis/ReprendreRefuse`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name EditerDevisCreate
     * @summary Génération du devis définitif (en double)
     * @request POST:/Dossier/{dossier}/Devis/Editer
     * @secure
     */
    editerDevisCreate: (
      { dossier, ...query }: EditerDevisCreateParams,
      data: EditerDevisCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Devis/Editer`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name ImportDevisCreate
     * @summary Importe le devis en PDF et crée un devis sans lignes en brouillon
     * @request POST:/Dossier/{dossier}/Devis/Import
     * @secure
     */
    importDevisCreate: (dossier: string, data: ImportDevisCreatePayload, params: RequestParams = {}) =>
      this.request<Devis, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Devis/Import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name ChangerStatutDevisPartialUpdate
     * @summary Validation d'un devis
     * @request PATCH:/Dossier/{dossier}/Devis/ChangerStatut
     * @secure
     */
    changerStatutDevisPartialUpdate: (
      dossier: string,
      data: ChangerStatutDevisPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Devis/ChangerStatut`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name DevisDossierDetail
     * @summary Récuperer les devis d'un dossier
     * @request GET:/Dossier/{dossier}/Devis
     * @secure
     */
    devisDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<DevisAvecPiecesJointes[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Devis`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name MediathequeDossierDetail
     * @request GET:/Dossier/{dossier}/Mediatheque
     * @secure
     */
    mediathequeDossierDetail: ({ dossier, ...query }: MediathequeDossierDetailParams, params: RequestParams = {}) =>
      this.request<ElementMediatheque[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Mediatheque`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name MediathequeDossierCreate
     * @request POST:/Dossier/{dossier}/Mediatheque
     * @secure
     */
    mediathequeDossierCreate: (dossier: string, data: MediathequeDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Mediatheque`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name DocumentDossierDetail
     * @request GET:/Dossier/{dossier}/Document
     * @secure
     */
    documentDossierDetail: ({ dossier, ...query }: DocumentDossierDetailParams, params: RequestParams = {}) =>
      this.request<Document[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Document`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name DocumentDossierCreate
     * @request POST:/Dossier/{dossier}/Document
     * @secure
     */
    documentDossierCreate: (dossier: string, data: DocumentDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Document`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name DossierDetail
     * @request GET:/Dossier/{id}
     * @secure
     */
    dossierDetail: (id: string, params: RequestParams = {}) =>
      this.request<Dossier, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name GetSyntheseDossierDossierDetail
     * @summary Récupère les informations de synthèse d'un dossier
     * @request GET:/Dossier/{dossier}/GetSyntheseDossier
     * @deprecated
     * @secure
     */
    getSyntheseDossierDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<SyntheseDossier, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/GetSyntheseDossier`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name HistoriqueDossierDetail
     * @summary Récupère l'historique du dossier
     * @request GET:/Dossier/{dossier}/Historique
     * @secure
     */
    historiqueDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<SuiviSimplifie[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Historique`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name HasNotationAssureDossierDetail
     * @request GET:/Dossier/{dossier}/HasNotationAssure
     * @secure
     */
    hasNotationAssureDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/HasNotationAssure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name NotationAssureDossierCreate
     * @request POST:/Dossier/{dossier}/NotationAssure
     * @secure
     */
    notationAssureDossierCreate: (
      { dossier, ...query }: NotationAssureDossierCreateParams,
      data: NotationAssureDossierCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/NotationAssure`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name FranchiseDossierDetail
     * @summary Retourne la franchise d'un dossier
     * @request GET:/Dossier/{dossier}/Franchise
     * @secure
     */
    franchiseDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Franchise`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name RseDossierDetail
     * @summary Retourne le taux RSE d'un dossier
     * @request GET:/Dossier/{dossier}/RSE
     * @secure
     */
    rseDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RSE`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name RemiseDossierDetail
     * @summary Retourne le taux de remise d'un dossier
     * @request GET:/Dossier/{dossier}/Remise
     * @secure
     */
    remiseDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Remise`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name DossierCreate
     * @summary Missionnement par une plateforme
     * @request POST:/Dossier
     * @secure
     */
    dossierCreate: (data: DossierCreatePayload, params: RequestParams = {}) =>
      this.request<RefDossier, ValidationProblemDetails>({
        path: `/Dossier`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name ChangesetDossierList
     * @summary Récupère les dossiers qui ont changé depuis une date donnée
     * @request GET:/Dossier/Changeset
     * @secure
     */
    changesetDossierList: (query: ChangesetDossierListParams, params: RequestParams = {}) =>
      this.request<ChangeSet[], ValidationProblemDetails>({
        path: `/Dossier/Changeset`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name NouveauEchangeInterneCreate
     * @summary Création d'un nouvel échange interne
     * @request POST:/Dossier/{dossier}/EchangeInterne/Nouveau
     * @secure
     */
    nouveauEchangeInterneCreate: (
      dossier: string,
      data: NouveauEchangeInterneCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/EchangeInterne/Nouveau`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name DestinatairesEchangeInterneDetail
     * @summary Permet de récupérer la liste des destinataires d'échanges internes pour un dossier
     * @request GET:/Dossier/{dossier}/EchangeInterne/Destinataires
     * @secure
     */
    destinatairesEchangeInterneDetail: (
      { dossier, ...query }: DestinatairesEchangeInterneDetailParams,
      params: RequestParams = {},
    ) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/EchangeInterne/Destinataires`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name PiedFactureCreate
     * @summary Calcule le pied d'une facture par rapport aux lignes saisies
     * @request POST:/Dossier/{dossier}/Facture/Pied
     * @secure
     */
    piedFactureCreate: (dossier: string, data: PiedFactureCreatePayload, params: RequestParams = {}) =>
      this.request<PiedFacture, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Facture/Pied`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name BrouillonFactureDetail
     * @summary Charge la facture brouillon de l'entreprise
     * @request GET:/Dossier/{dossier}/Facture/Brouillon
     * @secure
     */
    brouillonFactureDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<FactureAPI, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Facture/Brouillon`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name BrouillonFactureCreate
     * @summary Création/Modification d'une nouvelle facture en brouillon
     * @request POST:/Dossier/{dossier}/Facture/Brouillon
     * @secure
     */
    brouillonFactureCreate: (dossier: string, data: BrouillonFactureCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Facture/Brouillon`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name EditerFactureCreate
     * @summary Génération de la facture définitive (en double)
     * @request POST:/Dossier/{dossier}/Facture/Editer
     * @secure
     */
    editerFactureCreate: (
      { dossier, ...query }: EditerFactureCreateParams,
      data: EditerFactureCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Facture/Editer`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name ImportFactureCreate
     * @summary Importe la facture en PDF et crée une facture sans lignes en brouillon
     * @request POST:/Dossier/{dossier}/Facture/Import
     * @secure
     */
    importFactureCreate: (dossier: string, data: ImportFactureCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Facture/Import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name GenererRetrocommissionFactureCreate
     * @summary Génération d'une facture de rétro commission
     * @request POST:/Dossier/Facture/{facture}/GenererRetrocommission
     * @secure
     */
    genererRetrocommissionFactureCreate: (facture: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/Facture/${facture}/GenererRetrocommission`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name RefusFactureFacturePartialUpdate
     * @summary Refus d'une facture par l'assureur
     * @request PATCH:/Dossier/Facture/{facture}/RefusFacture
     * @secure
     */
    refusFactureFacturePartialUpdate: (
      facture: string,
      data: RefusFactureFacturePartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/Facture/${facture}/RefusFacture`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionDossierDetail
     * @request GET:/Dossier/{dossier}/Intervention
     * @secure
     */
    interventionDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Intervention[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Intervention`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionDossierCreate
     * @request POST:/Dossier/{dossier}/Intervention
     * @secure
     */
    interventionDossierCreate: (dossier: string, data: InterventionDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Intervention`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messagerie
     * @name MessagesDossierDetail
     * @summary Retourne tous les messages dont l'assuré est émetteur ou destinataire classés chronologiquement
     * @request GET:/Dossier/{dossier}/Messages
     * @secure
     */
    messagesDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Message[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Messages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messagerie
     * @name MessagesDossierCreate
     * @summary Création d'un nouvel échange interne
     * @request POST:/Dossier/{dossier}/Messages
     * @secure
     */
    messagesDossierCreate: (dossier: string, data: MessagesDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Messages`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messagerie
     * @name DestinatairesMessagesDetail
     * @summary Permet de récupérer la liste des destinataires des messages pour un dossier
     * @request GET:/Dossier/{dossier}/Messages/Destinataires
     * @secure
     */
    destinatairesMessagesDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Messages/Destinataires`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Missionnement
     * @name MissionnementDossierDetail
     * @summary Récupère le missionnement actif d'un dossier
     * @request GET:/Dossier/{id}/Missionnement
     * @secure
     */
    missionnementDossierDetail: (id: string, params: RequestParams = {}) =>
      this.request<Missionnement, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${id}/Missionnement`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Missionnement
     * @name MissionnementDossierCreate
     * @summary Missionne un établissement sur un dossier
     * @request POST:/Dossier/{id}/Missionnement
     * @secure
     */
    missionnementDossierCreate: (id: string, data: MissionnementDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${id}/Missionnement`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Missionnement
     * @name EtatMissionnementPartialUpdate
     * @summary Modifie l'état d'un missionnement en cours
     * @request PATCH:/Dossier/{id}/Missionnement/Etat
     * @secure
     */
    etatMissionnementPartialUpdate: (
      id: string,
      data: EtatMissionnementPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ForbidResult | ValidationProblemDetails>({
        path: `/Dossier/${id}/Missionnement/Etat`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personne
     * @name AssureDossierDetail
     * @request GET:/Dossier/{dossier}/Assure
     * @secure
     */
    assureDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Personne, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Assure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personne
     * @name AssureDossierCreate
     * @request POST:/Dossier/{dossier}/Assure
     * @secure
     */
    assureDossierCreate: (dossier: string, data: AssureDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Assure`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personne
     * @name CoordonneesAssurePartialUpdate
     * @request PATCH:/Dossier/{dossier}/Assure/Coordonnees
     * @secure
     */
    coordonneesAssurePartialUpdate: (
      dossier: string,
      data: CoordonneesAssurePartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Assure/Coordonnees`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recherche
     * @name RechercheDossierList
     * @request GET:/Dossier/Recherche
     * @secure
     */
    rechercheDossierList: (query: RechercheDossierListParams, params: RequestParams = {}) =>
      this.request<ResultatRechercheDossier, ValidationProblemDetails>({
        path: `/Dossier/Recherche`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recherche
     * @name RechercheParReferenceDossierDetail
     * @request GET:/Dossier/RechercheParReference/{codefamille}/{reference}
     * @secure
     */
    rechercheParReferenceDossierDetail: (codefamille: string, reference: string, params: RequestParams = {}) =>
      this.request<Dossier, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/RechercheParReference/${codefamille}/${reference}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name PriseDeContactDossierDetail
     * @request GET:/Dossier/{dossier}/PriseDeContact
     * @secure
     */
    priseDeContactDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<PriseDeContact[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/PriseDeContact`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name PriseDeContactDossierCreate
     * @request POST:/Dossier/{dossier}/PriseDeContact
     * @secure
     */
    priseDeContactDossierCreate: (
      dossier: string,
      data: PriseDeContactDossierCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<any, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/PriseDeContact`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name ProchainRendezVousMetreurRendezVousDetail
     * @request GET:/Dossier/{dossier}/RendezVous/ProchainRendezVousMetreur
     * @secure
     */
    prochainRendezVousMetreurRendezVousDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<RendezVousMetreur, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RendezVous/ProchainRendezVousMetreur`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name MetreurRendezVousDetail
     * @request GET:/Dossier/{dossier}/RendezVous/Metreur
     * @secure
     */
    metreurRendezVousDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<RendezVousMetreur[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RendezVous/Metreur`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name MetreurRendezVousCreate
     * @request POST:/Dossier/{dossier}/RendezVous/Metreur
     * @secure
     */
    metreurRendezVousCreate: (dossier: string, data: MetreurRendezVousCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RendezVous/Metreur`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name TravauxRendezVousDetail
     * @request GET:/Dossier/{dossier}/RendezVous/Travaux
     * @secure
     */
    travauxRendezVousDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<RendezVousTravaux[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RendezVous/Travaux`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name TravauxRendezVousCreate
     * @request POST:/Dossier/{dossier}/RendezVous/Travaux
     * @secure
     */
    travauxRendezVousCreate: (dossier: string, data: TravauxRendezVousCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RendezVous/Travaux`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowDossierDetail
     * @summary Récupère le Workflow du dossier
     * @request GET:/Dossier/{dossier}/Workflow
     * @secure
     */
    workflowDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<JalonSimplifie[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Workflow`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowEntrepriseDossierDetail
     * @summary Récupère le Workflow Entreprise du dossier
     * @request GET:/Dossier/{dossier}/WorkflowEntreprise
     * @secure
     */
    workflowEntrepriseDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<WorkflowEntreprise, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/WorkflowEntreprise`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  anomalieTypes = {
    /**
     * No description
     *
     * @tags AnomalieType
     * @name AnomalieTypesList
     * @request GET:/AnomalieTypes
     * @secure
     */
    anomalieTypesList: (params: RequestParams = {}) =>
      this.request<AnomalieType[], ValidationProblemDetails>({
        path: `/AnomalieTypes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  anomalieType = {
    /**
     * No description
     *
     * @tags AnomalieType
     * @name AnomalieTypeDetail
     * @request GET:/AnomalieType/{id}
     * @secure
     */
    anomalieTypeDetail: (id: number, params: RequestParams = {}) =>
      this.request<AnomalieType, ValidationProblemDetails>({
        path: `/AnomalieType/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnomalieType
     * @name AnomalieTypeUpdate
     * @request PUT:/AnomalieType/{id}
     * @secure
     */
    anomalieTypeUpdate: (id: number, data: AnomalieTypeUpdatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/AnomalieType/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnomalieType
     * @name AnomalieTypeDelete
     * @request DELETE:/AnomalieType/{id}
     * @secure
     */
    anomalieTypeDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/AnomalieType/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnomalieType
     * @name AnomalieTypeCreate
     * @request POST:/AnomalieType
     * @secure
     */
    anomalieTypeCreate: (data: AnomalieTypeCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/AnomalieType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  assureurs = {
    /**
     * No description
     *
     * @tags Assureur
     * @name AssureursList
     * @summary Liste des assureurs qui sont autorisés à se connecter à l'extranet
     * @request GET:/Assureurs
     * @secure
     */
    assureursList: (params: RequestParams = {}) =>
      this.request<AnnuaireResume[], ValidationProblemDetails | ProblemDetails>({
        path: `/Assureurs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  loginsAssureurs = {
    /**
     * No description
     *
     * @tags Assureur
     * @name LoginsAssureursList
     * @summary Retourne la liste des personnes paramétrées pour se connecter à l'extranet assureur
     * @request GET:/LoginsAssureurs
     * @secure
     */
    loginsAssureursList: (params: RequestParams = {}) =>
      this.request<AnnuaireLoginExtranetAssureurResume[], ValidationProblemDetails | ProblemDetails>({
        path: `/LoginsAssureurs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  loginAssureur = {
    /**
     * No description
     *
     * @tags Assureur
     * @name LoginAssureurCreate
     * @summary Création/Modification d'un login extranet Assureur
     * @request POST:/LoginAssureur
     * @secure
     */
    loginAssureurCreate: (data: LoginAssureurCreatePayload, params: RequestParams = {}) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/LoginAssureur`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  supprimerLoginAssureur = {
    /**
     * No description
     *
     * @tags Assureur
     * @name SupprimerLoginAssureurDelete
     * @summary Suppression d'une période de congès
     * @request DELETE:/SupprimerLoginAssureur/{idLogin}
     * @secure
     */
    supprimerLoginAssureurDelete: (idLogin: number, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/SupprimerLoginAssureur/${idLogin}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  authentification = {
    /**
     * No description
     *
     * @tags Authentification
     * @name LoginAuthentificationCreate
     * @request POST:/Authentification/Login
     * @secure
     */
    loginAuthentificationCreate: (data: LoginAuthentificationCreatePayload, params: RequestParams = {}) =>
      this.request<LoginResponse, ProblemDetails | ValidationProblemDetails>({
        path: `/Authentification/Login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name RegisterAuthentificationCreate
     * @request POST:/Authentification/Register
     * @secure
     */
    registerAuthentificationCreate: (data: RegisterAuthentificationCreatePayload, params: RequestParams = {}) =>
      this.request<LoginResponse, ProblemDetails | ValidationProblemDetails>({
        path: `/Authentification/Register`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginExtranetAssureAuthentificationCreate
     * @summary Authentification à l'API en tant qu'utilisateur extranet assuré
     * @request POST:/Authentification/LoginExtranetAssure
     * @secure
     */
    loginExtranetAssureAuthentificationCreate: (
      query: LoginExtranetAssureAuthentificationCreateParams,
      params: RequestParams = {},
    ) =>
      this.request<LoginResponseDossier, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/LoginExtranetAssure`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginExtranetAssureurAuthentificationCreate
     * @summary Authentification à l'API en tant qu'utilisateur extranet assureur
     * @request POST:/Authentification/LoginExtranetAssureur
     * @secure
     */
    loginExtranetAssureurAuthentificationCreate: (
      data: LoginExtranetAssureurAuthentificationCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<LoginResponse, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/LoginExtranetAssureur`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginExtranetEntrepriseAuthentificationCreate
     * @summary Authentification à l'API en tant qu'utilisateur extranet entreprise
     * @request POST:/Authentification/LoginExtranetEntreprise
     * @secure
     */
    loginExtranetEntrepriseAuthentificationCreate: (
      data: LoginExtranetEntrepriseAuthentificationCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<LoginResponse, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/LoginExtranetEntreprise`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name RegisterExtranetEntrepriseAuthentificationCreate
     * @summary Creation d'un utilisateur extranet entreprise
     * @request POST:/Authentification/RegisterExtranetEntreprise
     * @secure
     */
    registerExtranetEntrepriseAuthentificationCreate: (
      data: RegisterExtranetEntrepriseAuthentificationCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<ResetPasswordEntrepriseResponse, ProblemDetails | ValidationProblemDetails>({
        path: `/Authentification/RegisterExtranetEntreprise`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name ResetPasswordExtranetEntrepriseAuthentificationCreate
     * @summary Reset de mot de passe extranet entreprise
     * @request POST:/Authentification/ResetPasswordExtranetEntreprise
     * @secure
     */
    resetPasswordExtranetEntrepriseAuthentificationCreate: (
      data: ResetPasswordExtranetEntrepriseAuthentificationCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Authentification/ResetPasswordExtranetEntreprise`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name AskForPasswordResetExtranetEntrepriseAuthentificationCreate
     * @summary Demande de reset de mot de passe extranet entreprise
     * @request POST:/Authentification/AskForPasswordResetExtranetEntreprise
     * @secure
     */
    askForPasswordResetExtranetEntrepriseAuthentificationCreate: (
      data: AskForPasswordResetExtranetEntrepriseAuthentificationCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Authentification/AskForPasswordResetExtranetEntreprise`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginIrenAuthentificationCreate
     * @summary Authentification à l'API en tant qu'utilisateur IREN
     * @request POST:/Authentification/LoginIren
     * @secure
     */
    loginIrenAuthentificationCreate: (data: LoginIrenAuthentificationCreatePayload, params: RequestParams = {}) =>
      this.request<LoginResponse, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/LoginIren`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginIrenExtranetAuthentificationCreate
     * @summary Login depuis IREN à l'extranet entreprise ou assureur
     * @request POST:/Authentification/LoginIrenExtranet
     * @secure
     */
    loginIrenExtranetAuthentificationCreate: (
      query: LoginIrenExtranetAuthentificationCreateParams,
      params: RequestParams = {},
    ) =>
      this.request<LoginResponseDossier, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/LoginIrenExtranet`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  dashboard = {
    /**
     * No description
     *
     * @tags Dashboard
     * @name FacturesDashboardList
     * @request GET:/Dashboard/Factures
     * @secure
     */
    facturesDashboardList: (query: FacturesDashboardListParams, params: RequestParams = {}) =>
      this.request<ResultatDashboardFacture, ValidationProblemDetails>({
        path: `/Dashboard/Factures`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  mediatheque = {
    /**
     * No description
     *
     * @tags Document
     * @name CategorieMediathequeList
     * @request GET:/Mediatheque/Categorie
     * @secure
     */
    categorieMediathequeList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/Mediatheque/Categorie`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  echangeInterne = {
    /**
     * No description
     *
     * @tags EchangeInterne
     * @name EchangeInterneList
     * @summary Récupérer une liste filtrée de tous les échanges.
     * @request GET:/EchangeInterne
     * @secure
     */
    echangeInterneList: (query: EchangeInterneListParams, params: RequestParams = {}) =>
      this.request<EchangeInterne[], ValidationProblemDetails>({
        path: `/EchangeInterne`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name RepondreEchangeInterneCreate
     * @summary Permet de répondre à un échange interne
     * @request POST:/EchangeInterne/Repondre/{id}
     * @secure
     */
    repondreEchangeInterneCreate: (id: string, data: RepondreEchangeInterneCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EchangeInterne/Repondre/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name TransfertEchangeInterneCreate
     * @summary Permet de transférer un échange interne vers une ou plusieurs autres personnes
     * @request POST:/EchangeInterne/Transfert/{id}
     * @secure
     */
    transfertEchangeInterneCreate: (
      id: string,
      data: TransfertEchangeInterneCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EchangeInterne/Transfert/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name MarquerCommeLuEchangeInterneUpdate
     * @summary Marquer un message comme lu
     * @request PUT:/EchangeInterne/MarquerCommeLu/{id}
     * @secure
     */
    marquerCommeLuEchangeInterneUpdate: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EchangeInterne/MarquerCommeLu/${id}`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name MarquerCommeNonLuEchangeInterneUpdate
     * @summary Marquer un message comme non lu
     * @request PUT:/EchangeInterne/MarquerCommeNonLu/{id}
     * @secure
     */
    marquerCommeNonLuEchangeInterneUpdate: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EchangeInterne/MarquerCommeNonLu/${id}`,
        method: "PUT",
        secure: true,
        ...params,
      }),
  };
  entreprisePartenaires = {
    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name EntreprisePartenairesList
     * @summary Retourne la liste des entreprise partenaire de la BDD
     * @request GET:/EntreprisePartenaires
     * @secure
     */
    entreprisePartenairesList: (params: RequestParams = {}) =>
      this.request<EntreprisePartenaire[], ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaires`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  entreprisePartenaire = {
    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name EntreprisePartenaireDetail
     * @summary Retourne un objet entreprise partenaire complet
     * @request GET:/EntreprisePartenaire/{id}
     * @secure
     */
    entreprisePartenaireDetail: (id: number, params: RequestParams = {}) =>
      this.request<EntreprisePartenaire, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name EntreprisePartenaireUpdate
     * @summary Mettre à jour une entreprise Partenaire dans la BDD
     * @request PUT:/EntreprisePartenaire/{id}
     * @secure
     */
    entreprisePartenaireUpdate: (id: number, data: EntreprisePartenaireUpdatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name EntreprisePartenaireCreate
     * @summary Ajouter une nouvelle entreprise Partenaire dans la BDD
     * @request POST:/EntreprisePartenaire
     * @secure
     */
    entreprisePartenaireCreate: (data: EntreprisePartenaireCreatePayload, params: RequestParams = {}) =>
      this.request<number, ValidationProblemDetails>({
        path: `/EntreprisePartenaire`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name DocumentEntreprisePartenaireCreate
     * @summary Ajouter un document juridique à une entreprise Partenaire dans la BDD
     * @request POST:/EntreprisePartenaire/{idEntreprise}/Document
     * @secure
     */
    documentEntreprisePartenaireCreate: (
      idEntreprise: number,
      data: DocumentEntreprisePartenaireCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/${idEntreprise}/Document`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name DocumentEntreprisePartenaireUpdate
     * @summary Mettre à jour un document juridique d'une entreprise Partenaire dans la BDD
     * @request PUT:/EntreprisePartenaire/Document/{idDocument}
     * @secure
     */
    documentEntreprisePartenaireUpdate: (
      idDocument: number,
      data: DocumentEntreprisePartenaireUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/Document/${idDocument}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name DocumentEntreprisePartenaireDelete
     * @summary Suppression d'un document juridique d'une entreprise Partenaire dans la BDD
     * @request DELETE:/EntreprisePartenaire/Document/{idDocument}
     * @secure
     */
    documentEntreprisePartenaireDelete: (idDocument: number, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/Document/${idDocument}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name EtablissementEntreprisePartenaireCreate
     * @summary Ajoute un établissement à une entreprise Partenaire dans la BDD
     * @request POST:/EntreprisePartenaire/{idEntreprise}/Etablissement
     * @secure
     */
    etablissementEntreprisePartenaireCreate: (
      idEntreprise: number,
      data: EtablissementEntreprisePartenaireCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/${idEntreprise}/Etablissement`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name EtablissementEntreprisePartenaireUpdate
     * @summary Mettre à jour un etablissement d'une entreprise Partenaire dans la BDD
     * @request PUT:/EntreprisePartenaire/Etablissement/{idEtablissement}
     * @secure
     */
    etablissementEntreprisePartenaireUpdate: (
      idEtablissement: number,
      data: EtablissementEntreprisePartenaireUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/Etablissement/${idEtablissement}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  etablissement = {
    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name CongeEtablissementCreate
     * @summary Ajoute un congé à un etblissement dans la BDD
     * @request POST:/Etablissement/{idEtablissement}/Conge
     * @secure
     */
    congeEtablissementCreate: (
      idEtablissement: number,
      data: CongeEtablissementCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/${idEtablissement}/Conge`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name CongeEtablissementUpdate
     * @summary Mettre à jour un conge d'un etablissement dans la BDD
     * @request PUT:/Etablissement/Conge/{idConge}
     * @secure
     */
    congeEtablissementUpdate: (idConge: number, data: CongeEtablissementUpdatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/Conge/${idConge}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name CongeEtablissementDelete
     * @summary Suppression d'une période de congès
     * @request DELETE:/Etablissement/Conge/{idConge}
     * @secure
     */
    congeEtablissementDelete: (idConge: number, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/Conge/${idConge}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name HoraireEtablissementCreate
     * @summary Ajoute un horaire à un établissement dans la BDD
     * @request POST:/Etablissement/{idEtablissement}/Horaire
     * @secure
     */
    horaireEtablissementCreate: (
      idEtablissement: number,
      data: HoraireEtablissementCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/${idEtablissement}/Horaire`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name HoraireEtablissementUpdate
     * @summary Mettre à jour un horaire d'un etablissement dans la BDD
     * @request PUT:/Etablissement/{idEtablissement}/Horaire/{idHoraire}
     * @secure
     */
    horaireEtablissementUpdate: (
      idEtablissement: number,
      idHoraire: number,
      data: HoraireEtablissementUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/${idEtablissement}/Horaire/${idHoraire}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name ContactEtablissementCreate
     * @summary Ajoute un contact à un etblissement de l'entreprise partenaire dans la BDD
     * @request POST:/Etablissement/{idEtablissement}/Contact
     * @secure
     */
    contactEtablissementCreate: (
      idEtablissement: number,
      data: ContactEtablissementCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/${idEtablissement}/Contact`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name ContactEtablissementUpdate
     * @summary Mettre à jour un contact entreprise d'un etablissement dans la BDD
     * @request PUT:/Etablissement/Contact/{idContact}
     * @secure
     */
    contactEtablissementUpdate: (
      idContact: number,
      data: ContactEtablissementUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/Contact/${idContact}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name ContactEtablissementDelete
     * @summary Suppression d'un document juridique d'une entreprise Partenaire dans la BDD
     * @request DELETE:/Etablissement/Contact/{idContact}
     * @secure
     */
    contactEtablissementDelete: (idContact: number, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/Contact/${idContact}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name MetiersEtablissementUpdate
     * @summary Mettre à jour la liste des corps de metier de l'établissement dans la BDD
     * @request PUT:/Etablissement/{idEtablissement}/Metiers
     * @secure
     */
    metiersEtablissementUpdate: (
      idEtablissement: number,
      data: MetiersEtablissementUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/${idEtablissement}/Metiers`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name ZoneDeCouvertureEtablissementUpdate
     * @summary Mettre à jour la liste des zones de couverture de l'établissement dans la BDD
     * @request PUT:/Etablissement/{idEtablissement}/ZoneDeCouverture
     * @secure
     */
    zoneDeCouvertureEtablissementUpdate: (
      idEtablissement: number,
      data: ZoneDeCouvertureEtablissementUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/${idEtablissement}/ZoneDeCouverture`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  extranetEntrepriseUser = {
    /**
     * No description
     *
     * @tags ExtranetEntrepriseUser
     * @name RegisterAllExtranetEntrepriseExtranetEntrepriseUserCreate
     * @summary Creation d'un utilisateur pour tous les établissements
     * @request POST:/ExtranetEntrepriseUser/RegisterAllExtranetEntreprise
     * @secure
     */
    registerAllExtranetEntrepriseExtranetEntrepriseUserCreate: (params: RequestParams = {}) =>
      this.request<ResetPasswordEntrepriseResponse[], ProblemDetails | ValidationProblemDetails>({
        path: `/ExtranetEntrepriseUser/RegisterAllExtranetEntreprise`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExtranetEntrepriseUser
     * @name RegisterOneExtranetEntrepriseExtranetEntrepriseUserCreate
     * @summary Creation d'un utilisateur pour tous les établissements d'une entreprise
     * @request POST:/ExtranetEntrepriseUser/RegisterOneExtranetEntreprise/{id}
     * @secure
     */
    registerOneExtranetEntrepriseExtranetEntrepriseUserCreate: (id: number, params: RequestParams = {}) =>
      this.request<ResetPasswordEntrepriseResponse[], ProblemDetails | ValidationProblemDetails>({
        path: `/ExtranetEntrepriseUser/RegisterOneExtranetEntreprise/${id}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExtranetEntrepriseUser
     * @name ExtranetEntrepriseUserList
     * @request GET:/ExtranetEntrepriseUser
     * @secure
     */
    extranetEntrepriseUserList: (params: RequestParams = {}) =>
      this.request<ExtranetEntrepriseUser[], ValidationProblemDetails>({
        path: `/ExtranetEntrepriseUser`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  intervention = {
    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionUpdate
     * @request PUT:/Intervention/{id}
     * @secure
     */
    interventionUpdate: (id: number, data: InterventionUpdatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Intervention/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionDelete
     * @request DELETE:/Intervention/{id}
     * @secure
     */
    interventionDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Intervention/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name MotifInterventionList
     * @request GET:/Intervention/Motif
     * @secure
     */
    motifInterventionList: (params: RequestParams = {}) =>
      this.request<InterventionMotifNonReparation[], ValidationProblemDetails>({
        path: `/Intervention/Motif`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name ResultatInterventionInterventionList
     * @request GET:/Intervention/ResultatIntervention
     * @secure
     */
    resultatInterventionInterventionList: (params: RequestParams = {}) =>
      this.request<ResultatInterventionEnumLabel[], ValidationProblemDetails>({
        path: `/Intervention/ResultatIntervention`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name OrigineDemandeInterventionDiffereeInterventionList
     * @request GET:/Intervention/OrigineDemandeInterventionDifferee
     * @secure
     */
    origineDemandeInterventionDiffereeInterventionList: (params: RequestParams = {}) =>
      this.request<OrigineDemandeInterventionDiffereeEnumLabel[], ValidationProblemDetails>({
        path: `/Intervention/OrigineDemandeInterventionDifferee`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  logs = {
    /**
     * No description
     *
     * @tags Log
     * @name LogsList
     * @summary Récupère les dossiers selon un filtre
     * @request GET:/Logs
     * @secure
     */
    logsList: (query: LogsListParams, params: RequestParams = {}) =>
      this.request<File, ValidationProblemDetails | ProblemDetails>({
        path: `/Logs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  messages = {
    /**
     * No description
     *
     * @tags Messagerie
     * @name RepondreMessagesCreate
     * @summary Permet de répondre à un échange interne
     * @request POST:/Messages/{id}/Repondre
     * @secure
     */
    repondreMessagesCreate: (id: string, data: RepondreMessagesCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Messages/${id}/Repondre`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  missionnementMotifsRefus = {
    /**
     * No description
     *
     * @tags Missionnement
     * @name MissionnementMotifsRefusList
     * @summary Récupérer la liste des motifs du refus de la mission.
     * @request GET:/MissionnementMotifsRefus
     * @secure
     */
    missionnementMotifsRefusList: (query: MissionnementMotifsRefusListParams, params: RequestParams = {}) =>
      this.request<MissionnementMotifRefus[], ValidationProblemDetails | ProblemDetails>({
        path: `/MissionnementMotifsRefus`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  parametrage = {
    /**
     * No description
     *
     * @tags Parametrage
     * @name EurogexParametrageDetail
     * @request GET:/Parametrage/Eurogex/{section}/{rubrique}
     * @secure
     */
    eurogexParametrageDetail: (section: string, rubrique: string, params: RequestParams = {}) =>
      this.request<Eurogex, ValidationProblemDetails | ProblemDetails>({
        path: `/Parametrage/Eurogex/${section}/${rubrique}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Parametrage
     * @name EurogexParametrageList
     * @request GET:/Parametrage/Eurogex
     * @secure
     */
    eurogexParametrageList: (params: RequestParams = {}) =>
      this.request<Eurogex[], ValidationProblemDetails>({
        path: `/Parametrage/Eurogex`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  bordereau = {
    /**
     * No description
     *
     * @tags Recherche
     * @name RechercheBordereauList
     * @request GET:/Bordereau/Recherche
     * @secure
     */
    rechercheBordereauList: (query: RechercheBordereauListParams, params: RequestParams = {}) =>
      this.request<Bordereau[], ValidationProblemDetails>({
        path: `/Bordereau/Recherche`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  entreprise = {
    /**
     * No description
     *
     * @tags Recherche
     * @name RechercheEntrepriseList
     * @request GET:/Entreprise/Recherche
     * @secure
     */
    rechercheEntrepriseList: (query: RechercheEntrepriseListParams, params: RequestParams = {}) =>
      this.request<Etablissement[], ValidationProblemDetails>({
        path: `/Entreprise/Recherche`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  rendezVous = {
    /**
     * No description
     *
     * @tags RendezVous
     * @name MetreurRendezVousPartialUpdate
     * @request PATCH:/RendezVous/Metreur/{id}
     * @secure
     */
    metreurRendezVousPartialUpdate: (
      id: string,
      data: MetreurRendezVousPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/RendezVous/Metreur/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name TravauxRendezVousPartialUpdate
     * @request PATCH:/RendezVous/Travaux/{id}
     * @secure
     */
    travauxRendezVousPartialUpdate: (
      id: string,
      data: TravauxRendezVousPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/RendezVous/Travaux/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name RendezVousDelete
     * @request DELETE:/RendezVous/{id}
     * @secure
     */
    rendezVousDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/RendezVous/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  selectList = {
    /**
     * No description
     *
     * @tags SelectList
     * @name GetQuestionsExperienceAssureList
     * @summary Liste des questions à poser à l'assuré
     * @request GET:/SelectList/GetQuestionsExperienceAssure
     * @secure
     */
    getQuestionsExperienceAssureList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/GetQuestionsExperienceAssure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesDeLigneDevisList
     * @summary Liste des types de ligne pour les devis
     * @request GET:/SelectList/TypesDeLigneDevis
     * @secure
     */
    typesDeLigneDevisList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesDeLigneDevis`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TarifsList
     * @summary Liste des tarifs les devis
     * @request GET:/SelectList/Tarifs
     * @secure
     */
    tarifsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Tarifs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name UnitesMesureList
     * @summary Liste des unités de mesure pour les devis
     * @request GET:/SelectList/UnitesMesure
     * @secure
     */
    unitesMesureList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/UnitesMesure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TauxTvaList
     * @summary Liste des taux de TVA
     * @request GET:/SelectList/TauxTVA
     * @secure
     */
    tauxTvaList: (params: RequestParams = {}) =>
      this.request<TauxTVA[], ValidationProblemDetails>({
        path: `/SelectList/TauxTVA`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name StatutsDevisList
     * @request GET:/SelectList/StatutsDevis
     * @secure
     */
    statutsDevisList: (params: RequestParams = {}) =>
      this.request<StatutDevisEnumLabel[], ValidationProblemDetails>({
        path: `/SelectList/StatutsDevis`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name StatutsDossierList
     * @request GET:/SelectList/StatutsDossier
     * @secure
     */
    statutsDossierList: (params: RequestParams = {}) =>
      this.request<StatutDossier[], ValidationProblemDetails>({
        path: `/SelectList/StatutsDossier`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DepartementsList
     * @request GET:/SelectList/Departements
     * @secure
     */
    departementsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/Departements`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CompagniesDAssuranceList
     * @request GET:/SelectList/CompagniesDAssurance
     * @secure
     */
    compagniesDAssuranceList: (params: RequestParams = {}) =>
      this.request<CompagnieAssurance[], ValidationProblemDetails>({
        path: `/SelectList/CompagniesDAssurance`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesSinistresList
     * @request GET:/SelectList/NaturesSinistres
     * @secure
     */
    naturesSinistresList: (params: RequestParams = {}) =>
      this.request<NatureSinistre[], ValidationProblemDetails>({
        path: `/SelectList/NaturesSinistres`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CorpsMetierList
     * @request GET:/SelectList/CorpsMetier
     * @secure
     */
    corpsMetierList: (params: RequestParams = {}) =>
      this.request<IdLabel[], ValidationProblemDetails>({
        path: `/SelectList/CorpsMetier`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ContratEntrepriseList
     * @request GET:/SelectList/ContratEntreprise
     * @secure
     */
    contratEntrepriseList: (params: RequestParams = {}) =>
      this.request<IdLabel[], ValidationProblemDetails>({
        path: `/SelectList/ContratEntreprise`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name BordereauxCabinetList
     * @request GET:/SelectList/Cabinet/Bordereaux
     * @secure
     */
    bordereauxCabinetList: (params: RequestParams = {}) =>
      this.request<Bordereau[], ValidationProblemDetails>({
        path: `/SelectList/Cabinet/Bordereaux`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name BibliothequeDiversList
     * @request GET:/SelectList/BibliothequeDivers
     * @secure
     */
    bibliothequeDiversList: (params: RequestParams = {}) =>
      this.request<BibliothequeDivers[], ValidationProblemDetails>({
        path: `/SelectList/BibliothequeDivers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesDocumentsJuridiquesList
     * @request GET:/SelectList/TypesDocumentsJuridiques
     * @secure
     */
    typesDocumentsJuridiquesList: (params: RequestParams = {}) =>
      this.request<DocumentJuridiqueType[], ValidationProblemDetails>({
        path: `/SelectList/TypesDocumentsJuridiques`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name PostesContactsList
     * @request GET:/SelectList/PostesContacts
     * @secure
     */
    postesContactsList: (params: RequestParams = {}) =>
      this.request<string[], ValidationProblemDetails>({
        path: `/SelectList/PostesContacts`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesPrestationList
     * @request GET:/SelectList/TypesPrestation
     * @secure
     */
    typesPrestationList: (params: RequestParams = {}) =>
      this.request<TypePrestationEnumLabel[], ValidationProblemDetails>({
        path: `/SelectList/TypesPrestation`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsRefusFactureList
     * @request GET:/SelectList/MotifsRefusFacture
     * @secure
     */
    motifsRefusFactureList: (params: RequestParams = {}) =>
      this.request<IdLabel[], ValidationProblemDetails>({
        path: `/SelectList/MotifsRefusFacture`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EtatsFactureList
     * @request GET:/SelectList/EtatsFacture
     * @secure
     */
    etatsFactureList: (params: RequestParams = {}) =>
      this.request<EtatFactureEnumLabel, ValidationProblemDetails>({
        path: `/SelectList/EtatsFacture`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
